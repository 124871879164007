@import '../../styles/variables.scss';


.modal {
    height: 100vh;
    top: 0;

    .ant-modal-content {
        .ant-modal-body {}

        .ant-modal-footer {
            display: flex !important;
            justify-content: flex-end !important;
        }
    }
}

.keywords-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .header {}

    .keywords-list {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 180px) !important;
        overflow: auto !important;
        padding: 0 15px;


        .keyword-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 15px;
            margin-bottom: 10px;
            border: 1px solid $border-color2;
            border-radius: 5px;

            .score {
                background-color: $primary-color;
                color: white;
                // font-weight: bolder;
                font-size: 16px;
                padding: 5px 10px;
                border-radius: 5px;
            }
        }
    }
}