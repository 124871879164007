@import '../../styles/variables.scss';

.apps-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    // padding: 15px 20px;
    color: $color-graphite;

    .header {
        display: flex;
        width: 100%;
        padding: 0 15px;
        margin-bottom: 15px;
    }

    .list {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 230px) !important;
        overflow: auto !important;
        padding: 0 15px;
        width: 100%;

        .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 15px;
            margin-bottom: 10px;
            border: 1px solid $border-color2;
            border-radius: 5px;

            .title {}

            .status {

                .success {
                    color: green;
                }

                .error {
                    color: red;
                }
            }
        }
    }


}