@import "../../styles/variables.scss";

.backlog-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // width: 100%;
        text-align: center;
        margin: 0 15px;
        padding: 15px 0;
        border-bottom: 1px solid $border-color2;

        .select-country {
            margin-right: 0 !important;
        }
    }

    .actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0 15px;
        padding: 15px 0;
        border-bottom: 1px solid $border-color2;

        .pagination {
            width: 100%;
            padding: 0 !important;
            justify-content: flex-start !important;

            button {
                margin-bottom: 0 !important;
            }
        }

        .btn {
            margin-left: 10px;
        }
    }

    .apps-list {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        // height: 100%;
        width: 100%;
        padding: 0 20px;
    }
}


.limits-block {
    display: flex;
    width: fit-content;

    .field {
        max-width: 140px;
        margin-right: 10px;
    }
}