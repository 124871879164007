@import './variables.scss';

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 500px;
    width: 100%;
    padding: 50px 30px;
    border: 1px solid $border-color;
    margin: auto;
    border-radius: 10px;

    .title {
        font-size: 24px;
        color: $color-graphite;
        margin-bottom: 15px;
        text-align: center;
    }

    .field {
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;

        .label {
            color: $color-graphite;
        }

        input {
            border: 1px solid $border-color2;
            padding: 10px;
            border-radius: 5px;
        }


    }
}