@import "../../styles/variables.scss";

.home-wrapper {
    display: flex;
    width: 100%;

    .page-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .main-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $border-color;
            padding: 10px 15px;

            .nav {}

            .user {}
        }

        .page-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
        }

    }

}