@import '../../styles/variables.scss';

.app-wrapper {
    display: flex;
    align-items: center;
    background-color: $bg-container;
    width: 100%;
    border: 1px solid $border-color2;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 15px 20px;
    color: $color-graphite;

    .index {
        font-size: 24px;
        color: $color-graphite;
        // font-weight: bolder;
        margin-right: 20px;
    }

    .info-column {
        display: flex;
        align-items: center;

        .icon {
            max-width: 80px;
            max-height: 80px;
            border: 1px solid $border-color;
            height: 100%;
            width: 100%;
            overflow: hidden;
            border-radius: 10px;
            margin: 0 15px 0 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .text-info {
            display: flex;
            flex-direction: column;

            .title {
                font-size: 18px;
            }

            .genre {
                font-size: 14px;
            }

            .rating {
                .cnt {
                    margin-left: 10px;
                }
            }

        }
    }

    .action-column {
        display: flex;
        align-items: center;
        margin-left: auto;

        .btn {
            margin-left: 15px;
        }

    }

}