@import "../../styles/variables.scss";

.search-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px 0 0;
    overflow: auto;
    height: 100%;
    max-height: calc(100vh - 44px);

    .search-form {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0 20px;
        margin: 0 20px;
        border-bottom: 1px solid $border-color;

        .search-field {
            padding: 10px;
            margin-right: 15px;
            border: 1px solid $border-color2;
            border-radius: 10px;
            max-width: 500px;
            width: 100%;
        }





    }

    .func-block {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 10px 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid $border-color;

        button {
            margin: 0 10px;
        }
    }

    .apps-list {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        // height: 100%;
        width: 100%;
        padding: 0 20px;
    }
}