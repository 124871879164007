@import '../../styles/variables.scss';

.pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: auto;
    padding: 20px 0;

    .page-btn {
        margin: 0 5px 10px;
        padding: 5px 10px;
        background-color: white;
        border: 2px solid $border-color2;
        color: $color-graphite;
        font-weight: bolder;
        border-radius: 5px;
        font-size: 18px;
        cursor: pointer;
        transition: all 0.5s;

    }

    .active-page {
        background-color: $primary-color;
        color: white;
        border-color: $primary-color;
    }

    .page-btn :hover {
        background-color: $primary-color;
        color: white;
        border-color: $primary-color;
    }
}