@import '~antd/dist/antd.css';
@import './styles/variables.scss';
@import './styles/form.scss';

.main-wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 30px;
  right: 40px;
  font-size: 24px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: $border-color;
}

:root {
  .ant-menu {
    padding: 50px 30px !important;
  }

  .ant-select {
    margin-right: 15px !important;
    max-width: 200px;
    width: 100%;

    .ant-select-selector {
      border: 1px solid $border-color2;
      padding: 6px 10px;
      height: auto;
      border-radius: 10px;
    }


  }

  .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px !important;
    border-radius: 10px !important;
  }

  .ant-checkbox-wrapper {
    margin-left: 20px;
    transform: scale(1.5);
  }

}